import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'

import { Field, Button, List, Popup, NavBar, Picker, Toast, Loading } from 'vant';

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Field)
Vue.use(Button);
Vue.use(List);
Vue.use(Popup);
Vue.use(NavBar);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Loading);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
