import axios from 'axios'
import { Toast } from 'vant'
import Router from '@/router/index'

const requests = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000
})

const whiteUrls = ['/api/login']

requests.interceptors.request.use(config => {
    config.headers.setContentType('application/json;charset=utf-8');
    let token = localStorage.getItem('token')
    if (!token) {
        console.log(whiteUrls.indexOf(config.url))
        if (whiteUrls.indexOf(config.url) == -1) {
            Router.push('/');
            return Promise.reject(new Error('403'));
        }
    }
    config.headers.set('TOKEN', token)
    return config;
}, err => {
    return Promise.reject(err)
})

requests.interceptors.response.use(res => {
    if (res.data.code === 500) {
        Toast(res.data.msg)
        return Promise.reject(res.data.msg);
    }
    if (res.data.code === 403) {
        Toast('登录信息过期，请重新登录');
        Router.push('/')
        return;
    }
    return res.data.data;
}, err => {
    console.log(err)
    Toast(err.message);
    return new Promise.reject(err);
})

export default requests;