<!-- @format -->

<template>
  <div class="auth">
    <div class="postion"></div>
    <van-form class="form-wrap">
      <div class="form">
        <van-field v-model="username" name="用户名" label="用户名" placeholder="输入用户名"
          :rules="[{ required: true, message: '请填写用户名' }]" />
        <van-field v-model="password" type="password" name="密码" label="密码" placeholder="输入密码"
          :rules="[{ required: true, message: '请填写密码' }]" />
      </div>
    </van-form>
    <div style="margin: 16px;">
      <van-button round block type="info" native-type="submit" @click="toRouter">登录</van-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { login } from '@/api/api'
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    toRouter() {
      login({
        password: this.password,
        userName: this.username,
      }).then(res => {
        localStorage.setItem("token", res.token);
        if (res.admin) {
          this.$router.push({
            path: "/admin",
          });
        } else {
          this.$router.push({
            path: "/home",
          });
        }
      }).catch(err => { });
    },
  },
};
</script>
<style lang="scss" scoped>
.auth {
  height: calc(100vh - 50px);
  padding: 30px 20px 0 20px;
  background: #f5f5f5;

  .postion{
    height: calc(30vh);
  }
  .form-wrap {
    .form {
      border-radius: 25px;
      overflow: hidden;

      .van-cell:first-child {
        padding-top: 20px;
      }

      .van-cell:last-child {
        padding-bottom: 20px;
      }
    }
  }
}
</style>
