import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin.vue')
  },
  {
    path: '/addUser',
    name: 'addUser',
    component: () => import(/* webpackChunkName: "about" */ '../views/addUser.vue')
  },
  {
    path: '/addList',
    name: 'addList',
    component: () => import(/* webpackChunkName: "about" */ '../views/addList.vue')
  },
  {
    path: '/AdminHome',
    name: 'AdminHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminHome.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
