import requests  from "./http";


export const login = (data)=>{
    return requests.post('/api/login',data)
}


export const adminUserList = (data)=>{
    return requests.post('/api/admin/userList',data);
}

export const adminUserLinks = (data)=>{
    return requests.post('/api/admin/userLinks',data);
}

export const apiUserGetData = (data)=>{
    return requests.post('/api/user/getData',data);
}

export const adminDeleteUserLink = (data)=>{
    return requests.post('/api/admin/deleteUserLink',data);
}

export const adminAddUserLink = (data)=>{
    return requests.post('/api/admin/addUserLink',data);
}

export const adminLinkDetail = (data)=>{
    return requests.post('/api/admin/linkDetail',data);
}

export const adminUpdateUserLink = (data)=>{
    return requests.post('/api/admin/updateUserLink',data);
}

export const adminAddUser = (data)=>{
    return requests.post('/api/admin/addUser',data);
}

export const apiUserLinks =(data)=>{
    return requests.post('/api/user/links',data);
}